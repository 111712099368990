<template>
  <v-menu
    v-model="showMenu"
    :offset="[0, 10]"
    :close-on-content-click="false"
    disable-keys
  >
    <template #activator="{ props }">
      <v-btn
        :text="text"
        :icon="icon"
        :tile="tile"
        size="size"
        v-bind="props"
      />
    </template>
    <v-list>
      <v-list-item
        link
        @click="onClickNewQuery"
        density="compact"
      >
        <v-btn
          prepend-icon="mdi-application-braces"
          variant="tonal"
        >
          New Query
        </v-btn>
      </v-list-item>
      <v-progress-linear
        color="blue-lighten-3"
        indeterminate
        v-if="submenuLoading"
      ></v-progress-linear>
      <v-list-item v-if="submenuLoading">
        <v-list-item-title> Loading Submenu... </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-text-field
          v-model="searchQuery"
          density="compact"
          placeholder="Search queries"
          prepend-inner-icon="mdi-magnify"
        />
      </v-list-item>
      <TemplateQuerySubMenu
        v-if="getViewMenuItems?.length > 0 && !isDart"
        title="Views"
        :items="getViewMenuItems"
        :active="!!searchQuery"
        @close-menu="onCloseMenu"
      />
      <TemplateQuerySubMenu
        v-if="getManagedMenuItems?.length > 0 && !isDart"
        title="Queries"
        :items="getManagedMenuItems"
        :active="!!searchQuery"
        @close-menu="onCloseMenu"
      />
      <TemplateQuerySubMenu
        v-if="getTablesMenuItems?.length > 0 && !hideArsenal"
        title="Tables"
        :items="getTablesMenuItems"
        :active="!!searchQuery"
        @close-menu="onCloseMenu"
      />
      <TemplateQuerySubMenu
        v-if="getFunctionsMenuItems?.length > 0 && !hideArsenal"
        title="Arsenal Functions"
        :items="getFunctionsMenuItems"
        :active="!!searchQuery"
        @close-menu="onCloseMenu"
      />
      <!-- <TemplateQuerySubMenu
        v-if="getDashboardsMenuItems.length > 0"
        title="Dashboards"
        :items="getDashboardsMenuItems"
        :active="!!searchQuery"
        @close-menu="onCloseMenu"
        ></TemplateQuerySubMenu>-->
      <QuerySetSubMenu
        v-if="getQuerySetMenuItems?.length > 0"
        title="Query Sets"
        :items="getQuerySetMenuItems"
        :active="!!searchQuery"
        @close-menu="onCloseMenu"
      />
      <TemplateQuerySubMenu
        v-if="getInvestigationMenuItems?.length > 0"
        title="Investigation"
        :items="getInvestigationMenuItems"
        :active="!!searchQuery"
        :investigation-view="true"
        @close-menu="onCloseMenu"
      />
      <TemplateQuerySubMenu
        v-if="getIocMenuItems?.length > 0"
        title="IOC"
        :items="getIocMenuItems"
        :active="!!searchQuery"
        :investigation-view="false"
        @close-menu="onCloseMenu"
      />
      <TemplateQuerySubMenu
        v-if="getSsirpMenuItems?.length > 0"
        title="SSIRP"
        :items="getSsirpMenuItems"
        :active="!!searchQuery"
        :investigation-view="false"
        @close-menu="onCloseMenu"
      />
      <TemplateQuerySubMenu
        v-if="isDart"
        title="User Queries"
        :items="getUserQueriesMenuItems"
        :active="!!searchQuery"
        @close-menu="onCloseMenu"
      />
    </v-list>
  </v-menu>
</template>
<script setup lang="ts">
import {
  createNewQueryComponent,
  createNewTemplateQueryComponent,
  templateQueriesAsObject,
} from '@/renderer/displayComponent';
import TemplateQuerySubMenu from '@/components/TemplateQuerySubMenu.vue';
import QuerySetSubMenu from '@/components/QuerySetSubMenu.vue';

import { ref, computed } from 'vue';
import { useStore } from '@/store/store';
import { QueryTemplate } from '@/renderer/kusto_queries';
import { QuerySet } from '@/renderer/kusto_queries';

// Store
const store = useStore();
// Data
const searchQuery = ref<string>('');
const showMenu = ref<boolean>(false);
const isSubmenuLoading = ref<boolean>(false);

// Props
const props = defineProps({
  uuid: {
    type: String,
    required: false,
    default: '',
  },
  text: {
    type: String,
    default: 'New',
  },
  small: Boolean,
  icon: {
    type: String,
    default: 'mdi-plus',
  },
  tile: Boolean,
});

// Emitters
const emit = defineEmits(['update:active-uuid']);

// Getters
const getQueryOption = (uuid: string) => {
  return store.getters['queries/getQueryOption'](uuid);
};
const getViewTemplates = (): QueryTemplate[] => {
  return store.getters['queries/getViewTemplates'];
};
const getQueryTemplates = (): QueryTemplate[] => {
  return store.getters['queries/getQueryTemplates'];
};
const getTemplatesByClass = (queryClass: string) => {
  return store.getters['queries/getTemplatesByClass'](queryClass);
};
const getManagedTemplates = (queryClasses: string[]): QueryTemplate[] => {
  return store.getters['queries/getManagedTemplates'](queryClasses);
};
const getAllQuerySets = (): QuerySet[] => {
  return store.getters['queries/getAllQuerySets'];
};
const getUserTemplates = () => {
  return store.getters['queries/getUserTemplates'];
};
// Computed
const getViewMenuItems = computed(() => {
  const templates = getViewTemplates()?.filter(
    (queryTemplate) => getQueryOption(queryTemplate?.uuid).hide !== true,
  );
  return filterItemsAsObject(templates);
});

const getQueryMenuItems = computed(() => {
  const templates = getQueryTemplates()?.filter(
    (queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true,
  );
  return filterItemsAsObject(templates);
});

const getFunctionsMenuItems = computed(() => {
  const templates = getTemplatesByClass('Functions')?.filter(
    (queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true,
  );
  return filterItemsAsObject(templates);
});

const getManagedMenuItems = computed(() => {
  const templates = getManagedTemplates([
    'Functions',
    'Tables',
    'SSIRP',
    'Investigation',
    'IOC',
  ]);
  return filterItemsAsObject(templates);
});

const getTablesMenuItems = computed(() => {
  const templates = getTemplatesByClass('Tables')?.filter(
    (queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true,
  );
  return filterItemsAsObject(templates);
});

const getInvestigationMenuItems = computed(() => {
  const templates = getTemplatesByClass('Investigation')
    .filter((queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true)
    .filter((queryTemplate) => !queryTemplate?.queryClass?.includes('SSIRP')); // Don't show SSIRP queries in 2 places
  return filterItemsAsObject(templates, false);
});

const getIocMenuItems = computed(() => {
  const templates = getTemplatesByClass('IOC').filter(
    (queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true,
  );
  return filterItemsAsObject(templates, false);
});

const getSsirpMenuItems = computed(() => {
  const templates = getTemplatesByClass('SSIRP').filter(
    (queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true,
  );
  return filterItemsAsObject(templates, false);
});

const getDashboardsMenuItems = computed(() => {
  const templates = getTemplatesByClass('Dashboards').filter(
    (queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true,
  );
  return filterItemsAsObject(templates);
});

const getQuerySetMenuItems = computed(() => {
  const querySets = getAllQuerySets();
  const items = filterItemsAsObject(querySets);
  return items;
});

const getUserQueriesMenuItems = computed(() => {
  const templates = getUserTemplates()?.filter(
    (queryTemplate) => getQueryOption(queryTemplate.uuid).hide !== true,
  );
  return filterItemsAsObject(templates);
});

const isDart = computed(() => {
  return process.env.VUE_APP_IS_DART;
});

const hideArsenal = computed(() => {
  return process.env.VUE_APP_HIDE_ARSENAL === 'true';
});

const size = computed(() => {
  return props.small ? 'small' : 'default';
});

// Methods
const submenuLoading = computed(() => {
  return !getViewMenuItems.value;
});
const onCloseMenu = function () {
  showMenu.value = false;
};

const filterItemsAsObject = (items, nestedMenu = true) => {
  let itemResult = items;
  if (searchQuery.value && items.length > 0) {
    let _searchQuery = searchQuery.value.toLowerCase();
    itemResult = items.filter((item) => {
      const itemString = JSON.stringify([item.menu, item.summary]);
      return itemString.toLowerCase().includes(_searchQuery);
    });
  }
  return nestedMenu ? templateQueriesAsObject(itemResult) : itemResult;
};

const onClickNewQuery = async () => {
  const uuid: string = await createNewQueryComponent('New query');
  emit('update:active-uuid', uuid);
  showMenu.value = false;
};

const onClickNewView = async function (queryTemplate) {
  await createNewTemplateQueryComponent(
    queryTemplate.summary,
    queryTemplate,
    queryTemplate.getDefaultParams(),
    null,
    false,
    true,
  );
};
</script>

<style></style>
